// FONTS

/* montserrat-100 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-Thin.ttf') format('truetype');
}
/* montserrat-100italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-ThinItalic.ttf') format('truetype');
}
/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-ExtraLight.ttf') format('truetype');
}
/* montserrat-200italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-LightItalic.ttf') format('truetype');
}
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-Light.ttf') format('truetype');
}
/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-LightItalic.ttf') format('truetype');
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-Regular.ttf') format('truetype');
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-Italic.ttf') format('truetype');
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-Medium.ttf') format('truetype');
}
/* montserrat-500italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-MediumItalic.ttf') format('truetype');
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
}
/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-Bold.ttf') format('truetype');
}
/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-BoldItalic.ttf') format('truetype');
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
}
/* montserrat-800italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}
/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-Black.ttf') format('truetype');
}
/* montserrat-900italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src:
    local('☺︎'),
    url('./fonts/Montserrat-BlackItalic.ttf') format('truetype');
}
